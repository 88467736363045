const { default: axios } = require('axios');

require('./bootstrap');

check();

$('.selectedAll').on('click', function(){
    if (this.checked) {
        $('.checkpointCheckbox:checkbox').each(function(){
            this.checked = true;
        });
    } else {
        $('.checkpointCheckbox:checkbox').each(function(){
            this.checked = false;
        });
    }

    check();
});

$('.checkpointCheckbox').on('click', function(){
    if ($('.checkpointCheckbox:checked').length == $('.checkpointCheckbox:checkbox').length) {
        $('.selectedAll').attr('checked', 'checked').prop('checked', 'checked');
    }else{
        $('.selectedAll').removeAttr('checked');
    }

    check();
});

function check() {
    if ($('.checkpointCheckbox:checked').length) {
        $('.exportGroup').show();
    } else {
        $('.exportGroup').hide();
    }
}

window.logout = function() {
    axios.post(`/auth/logout`)
    .then(function (response) {
        if (localStorage.getItem('frontend_token')) {
            localStorage.removeItem('frontend_token');
        }

        window.location.pathname = '/auth/login';
    });
}